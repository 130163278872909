import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

const Footer = props => {
  // These hooks set the visibility for the back to top button :
  const [visibility, setVisibility] = useState("none");
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 750) {
        setVisibility("block");
      } else if (window.scrollY < 100) {
        setVisibility("none");
      }
    };
    document.addEventListener("scroll", onScroll);
    return document.addEventListener("scroll", onScroll);
  }, [visibility]);

  const { theme } = props;
  const buildTime = useStaticQuery(query).site.buildTime;
  // Back to top function with smooth behavior
  const scrollToTop = e => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  // TO DO : Add animation when the button appears (see React Spring)

  return (
    <React.Fragment>
      <footer className="footer">
        <button onClick={scrollToTop} className={`back-to-top ${visibility}`}>
          TOP
        </button>
        Ce blog est basé sur le starter de{" "}
        <a
          href="https://www.github.com/baobabKoodaa/blog/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Baobab
        </a>
        . Dernière mise à jour : {buildTime}.
      </footer>

      {/* --- STYLES --- */}
      <style jsx>{`
        .footer {
          background: ${theme.color.neutral.white};
          padding: ${theme.space.inset.default};
          text-align: center;
          color: ${theme.color.neutral.gray.g};
          font-size: ${theme.font.size.xxs};
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 60px;
        }
        .back-to-top {
          cursor: pointer;
          position: fixed;
          bottom: ${theme.space.l};
          right: ${theme.space.l};
          width: ${theme.space.xl};
          height: ${theme.space.xl};
          background: ${theme.color.brand.primary};
          border: 0;
          border-radius: 50%;
          color: ${theme.color.neutral.white};
          font-size: ${theme.font.size.s};
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.7;
          &:focus {
            outline-style: none;
            background: ${theme.color.brand.primary.active};
          }
          &:hover {
            opacity: 1;
          }
        }
        .none {
          display: none;
        }
        .block {
          display: block;
        }
      `}</style>
    </React.Fragment>
  );
};

Footer.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Footer;

const query = graphql`
  query Info {
    site {
      buildTime(formatString: "DD.MM.YYYY HH:mm")
    }
  }
`;
